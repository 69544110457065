import React from 'react';
import { Redirect, Router } from '@reach/router';
import { useIntl } from 'gatsby-plugin-intl';
import { ArtworkCollections } from '@/artwork/components/artwork-collections';
import NotFoundPage from '@/pages/404';
import { EditProfile } from '@/profile/pages/edit-profile';
import { EmailVerification } from '@/profile/pages/email-verification';
import { ProfileCompleted } from '@/profile/pages/profile-completed';
import { AuthenticatedRoute } from '@/profile/routes/authenticated-route';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Profile = function Profile() {
  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  return ___EmotionJSX(Router, {
    basepath: "/" + locale + "/profile/"
  }, ___EmotionJSX(Redirect, {
    from: "/",
    to: "/",
    path: "/",
    noThrow: true
  }), ___EmotionJSX(EmailVerification, {
    path: "email-verification/"
  }), ___EmotionJSX(ProfileCompleted, {
    path: "completed/"
  }), ___EmotionJSX(AuthenticatedRoute, {
    component: EditProfile,
    path: "edit/"
  }), ___EmotionJSX(AuthenticatedRoute, {
    component: ArtworkCollections,
    path: "collection/"
  }), ___EmotionJSX(NotFoundPage, {
    default: true
  }));
};

Profile.displayName = "Profile";
export { Profile as default };