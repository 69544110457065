import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from 'react';
import { InfoIcon } from '../info-icon';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Container = _styled("div", {
  target: "e1y1mqdh0",
  label: "status-indicator_Container"
})(process.env.NODE_ENV === "production" ? {
  name: "4ng264",
  styles: "align-items:center;display:flex;flex-direction:row;"
} : {
  name: "4ng264",
  styles: "align-items:center;display:flex;flex-direction:row;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3N0YXR1cy1pbmRpY2F0b3Ivc3RhdHVzLWluZGljYXRvci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSzRCIiwiZmlsZSI6Ii9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3N0YXR1cy1pbmRpY2F0b3Ivc3RhdHVzLWluZGljYXRvci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5pbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnO1xuXG5pbXBvcnQgeyBJbmZvSWNvbiB9IGZyb20gJy4uL2luZm8taWNvbic7XG5cbmNvbnN0IENvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG5gO1xuY29uc3QgU3RhdHVzID0gc3R5bGVkLnNwYW5gXG4gIG1hcmdpbi1sZWZ0OiA5cHg7XG5gO1xuXG5leHBvcnQgaW50ZXJmYWNlIFN0YXR1c0luZGljYXRvclByb3BzIHtcbiAgc3RhdHVzOiBhbnk7XG59XG5cbmNvbnN0IFN0YXR1c0luZGljYXRvcjogUmVhY3QuRkM8U3RhdHVzSW5kaWNhdG9yUHJvcHM+ID0gKHsgc3RhdHVzIH0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8Q29udGFpbmVyPlxuICAgICAgPEluZm9JY29uIC8+XG4gICAgICA8U3RhdHVzPntzdGF0dXN9PC9TdGF0dXM+XG4gICAgPC9Db250YWluZXI+XG4gICk7XG59O1xuXG5leHBvcnQgeyBTdGF0dXNJbmRpY2F0b3IgfTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var Status = _styled("span", {
  target: "e1y1mqdh1",
  label: "status-indicator_Status"
})(process.env.NODE_ENV === "production" ? {
  name: "1mf4y6e",
  styles: "margin-left:9px;"
} : {
  name: "1mf4y6e",
  styles: "margin-left:9px;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3N0YXR1cy1pbmRpY2F0b3Ivc3RhdHVzLWluZGljYXRvci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBVTBCIiwiZmlsZSI6Ii9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3N0YXR1cy1pbmRpY2F0b3Ivc3RhdHVzLWluZGljYXRvci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5pbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnO1xuXG5pbXBvcnQgeyBJbmZvSWNvbiB9IGZyb20gJy4uL2luZm8taWNvbic7XG5cbmNvbnN0IENvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG5gO1xuY29uc3QgU3RhdHVzID0gc3R5bGVkLnNwYW5gXG4gIG1hcmdpbi1sZWZ0OiA5cHg7XG5gO1xuXG5leHBvcnQgaW50ZXJmYWNlIFN0YXR1c0luZGljYXRvclByb3BzIHtcbiAgc3RhdHVzOiBhbnk7XG59XG5cbmNvbnN0IFN0YXR1c0luZGljYXRvcjogUmVhY3QuRkM8U3RhdHVzSW5kaWNhdG9yUHJvcHM+ID0gKHsgc3RhdHVzIH0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8Q29udGFpbmVyPlxuICAgICAgPEluZm9JY29uIC8+XG4gICAgICA8U3RhdHVzPntzdGF0dXN9PC9TdGF0dXM+XG4gICAgPC9Db250YWluZXI+XG4gICk7XG59O1xuXG5leHBvcnQgeyBTdGF0dXNJbmRpY2F0b3IgfTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var StatusIndicator = function StatusIndicator(_ref) {
  var status = _ref.status;
  return ___EmotionJSX(Container, null, ___EmotionJSX(InfoIcon, null), ___EmotionJSX(Status, null, status));
};

StatusIndicator.displayName = "StatusIndicator";
export { StatusIndicator };