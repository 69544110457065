import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var Action = _styled("button", {
  target: "e1vldbcq0",
  label: "action_Action"
})(process.env.NODE_ENV === "production" ? {
  name: "ap9vgg",
  styles: "align-items:center;background-color:transparent;border:none;border-bottom:1px solid #676767;color:white;cursor:pointer;display:flex;justify-content:flex-start;margin:0;min-height:2.72rem;padding:0;text-align:left;width:100%;&:disabled{background-color:transparent;color:#747474;cursor:default;}&:last-child{border-bottom:none;}"
} : {
  name: "ap9vgg",
  styles: "align-items:center;background-color:transparent;border:none;border-bottom:1px solid #676767;color:white;cursor:pointer;display:flex;justify-content:flex-start;margin:0;min-height:2.72rem;padding:0;text-align:left;width:100%;&:disabled{background-color:transparent;color:#747474;cursor:default;}&:last-child{border-bottom:none;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2FjdGlvbi9hY3Rpb24udHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUU0QiIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy9hY3Rpb24vYWN0aW9uLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcblxuY29uc3QgQWN0aW9uID0gc3R5bGVkLmJ1dHRvbmBcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gIGJvcmRlcjogbm9uZTtcbiAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICM2NzY3Njc7XG4gIGNvbG9yOiB3aGl0ZTtcbiAgY3Vyc29yOiBwb2ludGVyO1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gIG1hcmdpbjogMDtcbiAgbWluLWhlaWdodDogMi43MnJlbTtcbiAgcGFkZGluZzogMDtcbiAgdGV4dC1hbGlnbjogbGVmdDtcbiAgd2lkdGg6IDEwMCU7XG5cbiAgJjpkaXNhYmxlZCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gICAgY29sb3I6ICM3NDc0NzQ7XG4gICAgY3Vyc29yOiBkZWZhdWx0O1xuICB9XG5cbiAgJjpsYXN0LWNoaWxkIHtcbiAgICBib3JkZXItYm90dG9tOiBub25lO1xuICB9XG5gO1xuXG5leHBvcnQgeyBBY3Rpb24gfTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export { Action };