import React from 'react';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { useTranslation } from '@/common/hooks';
import { EmailVerificationInfo as EmailVerificationView } from '@/profile/components/email-verification';
import { jsx as ___EmotionJSX } from "@emotion/core";

var EmailVerification = function EmailVerification() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: t('emailVerification.meta.title')
  }), ___EmotionJSX(EmailVerificationView, null));
};

EmailVerification.displayName = "EmailVerification";
export { EmailVerification };