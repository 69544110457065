import React from 'react';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { useTranslation } from '@/common/hooks';
import { ProfileCompletedInfo as ProfileCompletedView } from '@/profile/components/profile-completed';
import { jsx as ___EmotionJSX } from "@emotion/core";

var ProfileCompleted = function ProfileCompleted() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: t('profileCompleted.meta.title')
  }), ___EmotionJSX(ProfileCompletedView, null));
};

ProfileCompleted.displayName = "ProfileCompleted";
export { ProfileCompleted };