import React from 'react';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { ProfileDetails } from '@/profile/components/profile-details';
import { jsx as ___EmotionJSX } from "@emotion/core";

var EditProfile = function EditProfile() {
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: "Profile",
    meta: [{
      name: 'robots',
      content: 'noindex'
    }]
  }), ___EmotionJSX(ProfileDetails, null));
};

EditProfile.displayName = "EditProfile";
export { EditProfile };