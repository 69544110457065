import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useArtworkDownload } from '@/artwork/hooks/download-artwork-hook';
import { Action } from '@/common/components/action';
import { BrandedLoadingIndicator } from '@/common/components/loading-indicator';
import { Tooltip } from '@/common/components/tooltip';
import { useOffline } from '@/common/hooks/offline-hook';
import { jsx as ___EmotionJSX } from "@emotion/core";
var brandedLoadingIndicatorStyle = {
  width: '30px',
  marginLeft: 'auto',
  padding: 0
};

var ArtworkDownload = function ArtworkDownload(_ref) {
  var collection = _ref.collection;
  var artwork = collection.artwork,
      distinctEdition = collection.distinctEdition;
  var offline = useOffline();

  var _useArtworkDownload = useArtworkDownload(artwork.id, distinctEdition),
      handleArtworkDownload = _useArtworkDownload.handleArtworkDownload,
      isDownloading = _useArtworkDownload.isDownloading;

  return ___EmotionJSX(Action, {
    onClickCapture: handleArtworkDownload,
    disabled: offline
  }, ___EmotionJSX(FormattedMessage, {
    id: "collections.actions.downloadArtwork"
  }), isDownloading && ___EmotionJSX(BrandedLoadingIndicator, {
    style: brandedLoadingIndicatorStyle,
    colorless: true
  }), offline && ___EmotionJSX(Tooltip, {
    errorMode: true
  }, ___EmotionJSX(FormattedMessage, {
    id: "collections.actions.notAvailableOffline"
  })));
};

ArtworkDownload.displayName = "ArtworkDownload";
export { ArtworkDownload };