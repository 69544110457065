import getYear from "date-fns/getYear";

var range = function range(start, stop, step) {
  return Array.from({
    length: (stop - start) / step + 1
  }, function (_, i) {
    return start + i * step;
  });
};

var getYearsOptions = function getYearsOptions() {
  return range(getYear(new Date()), 1900, -1).reverse();
};

var getMonthsOptions = function getMonthsOptions() {
  return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
};

export { getMonthsOptions, getYearsOptions, range };