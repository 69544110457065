var MintingTransactionState;

(function (MintingTransactionState) {
  MintingTransactionState["QUEUED"] = "QUEUED";
  MintingTransactionState["SENDING"] = "SENDING";
  MintingTransactionState["MINING"] = "MINING";
  MintingTransactionState["CONFIRMED"] = "CONFIRMED";
  MintingTransactionState["FAILED"] = "FAILED";
})(MintingTransactionState || (MintingTransactionState = {}));

export { MintingTransactionState };