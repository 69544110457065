import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { BrandedLoadingIndicator } from '@/common/components/loading-indicator/branded-loading-indicator';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Base = _styled("div", {
  target: "e1a60byg0",
  label: "loading-screen_Base"
})(process.env.NODE_ENV === "production" ? {
  name: "1284wdw",
  styles: "align-items:center;display:flex;flex-direction:column;justify-content:center;margin:0 auto;min-height:60vh;width:100px;"
} : {
  name: "1284wdw",
  styles: "align-items:center;display:flex;flex-direction:column;justify-content:center;margin:0 auto;min-height:60vh;width:100px;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2xvYWRpbmctaW5kaWNhdG9yL2xvYWRpbmctc2NyZWVuLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFPdUIiLCJmaWxlIjoiL2J1aWxkcy9tb29uYS9tb29uYS1mcm9udGVuZC9zcmMvY29tbW9uL2NvbXBvbmVudHMvbG9hZGluZy1pbmRpY2F0b3IvbG9hZGluZy1zY3JlZW4udHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnO1xuaW1wb3J0IFJlYWN0LCB7IENTU1Byb3BlcnRpZXMgfSBmcm9tICdyZWFjdCc7XG5cbmltcG9ydCB7IEZvcm1hdHRlZE1lc3NhZ2UgfSBmcm9tICdnYXRzYnktcGx1Z2luLWludGwnO1xuXG5pbXBvcnQgeyBCcmFuZGVkTG9hZGluZ0luZGljYXRvciB9IGZyb20gJ0AvY29tbW9uL2NvbXBvbmVudHMvbG9hZGluZy1pbmRpY2F0b3IvYnJhbmRlZC1sb2FkaW5nLWluZGljYXRvcic7XG5cbmNvbnN0IEJhc2UgPSBzdHlsZWQuZGl2YFxuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgbWFyZ2luOiAwIGF1dG87XG4gIG1pbi1oZWlnaHQ6IDYwdmg7XG4gIHdpZHRoOiAxMDBweDtcbmA7XG5cbmV4cG9ydCBpbnRlcmZhY2UgTG9hZGluZ1NjcmVlblByb3BzIHtcbiAgc3R5bGU/OiBDU1NQcm9wZXJ0aWVzO1xuICBjbGFzc05hbWU/OiBzdHJpbmc7XG59XG5cbmNvbnN0IExvYWRpbmdTY3JlZW46IFJlYWN0LkZDPExvYWRpbmdTY3JlZW5Qcm9wcz4gPSAoeyBzdHlsZSwgY2xhc3NOYW1lIH0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8QmFzZSBzdHlsZT17c3R5bGV9IGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgIDxCcmFuZGVkTG9hZGluZ0luZGljYXRvcj5cbiAgICAgICAgPEZvcm1hdHRlZE1lc3NhZ2UgaWQ9eydnZW5lcmFsLmxvYWRpbmcnfSAvPlxuICAgICAgPC9CcmFuZGVkTG9hZGluZ0luZGljYXRvcj5cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBMb2FkaW5nU2NyZWVuIH07XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var LoadingScreen = function LoadingScreen(_ref) {
  var style = _ref.style,
      className = _ref.className;
  return ___EmotionJSX(Base, {
    style: style,
    className: className
  }, ___EmotionJSX(BrandedLoadingIndicator, null, ___EmotionJSX(FormattedMessage, {
    id: 'general.loading'
  })));
};

LoadingScreen.displayName = "LoadingScreen";
export { LoadingScreen };