import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useWithdrawToken() {
  var withdrawTokens = useStoreState(function (state) {
    return state.withdrawToken.tokens;
  });
  var setWithdrawTokenStatus = useStoreActions(function (actions) {
    return actions.withdrawToken.setWithdrawTokenStatus;
  });
  return {
    withdrawTokens: withdrawTokens,
    setWithdrawTokenStatus: setWithdrawTokenStatus
  };
}

export { useWithdrawToken };